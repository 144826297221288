document.addEventListener("PopupBannerLoaded", () => {
  console.log("PopupBannerLoaded!")

  const RZ_PAGES_VISITED = "RZ_PAGES_VISITED"
  const RZ_FIRST_SEEN = "RZ_FIRST_SEEN"
  const RZ_LAST_SEEN = "RZ_LAST_SEEN"
  const RZ_BANNER_SHOWN = "RZ_BANNER_SHOWN"

  const INACTIVITY_PERIOD = document.getElementById("rz_inactivity_period").value * 1000
  const PAGES_VISITED = document.getElementById("rz_pages_visited").value
  const BEEN_ACTIVE = document.getElementById("rz_been_active").value * 1000

  let timer

  function showPopUpBanner() {
    if (localStorage.getItem(RZ_BANNER_SHOWN))
      return

    let el = document.getElementById("popup-banner-container")
    el && el.classList.add("_visible")
    localStorage.removeItem(RZ_FIRST_SEEN)
    localStorage.setItem(RZ_BANNER_SHOWN, "1")
    if (timer) {
      clearInterval(timer)
    }

    if (typeof (dataLayer) !== 'undefined') {
      dataLayer.push({
        event: "UNIVERSAL_EVENT",
        eventName: "selection_by_disks_show_banner"
      });
    }
  }

  function initUserActivityTracking() {
    console.log("track user activity")
    let lastSeen = parseInt(localStorage.getItem(RZ_LAST_SEEN)) || Date.now()
    if (Date.now() - lastSeen > INACTIVITY_PERIOD) {
      console.log('Welcome back!')
      localStorage.removeItem(RZ_PAGES_VISITED)
      localStorage.removeItem(RZ_FIRST_SEEN)
      localStorage.removeItem(RZ_LAST_SEEN)
      localStorage.removeItem(RZ_BANNER_SHOWN)
    }
    let pagesVisited = parseInt(localStorage.getItem(RZ_PAGES_VISITED)) || 0
    localStorage.setItem(RZ_PAGES_VISITED, ++pagesVisited)
    localStorage.setItem(RZ_LAST_SEEN, Date.now())
    let firstSeen = parseInt(localStorage.getItem(RZ_FIRST_SEEN))
    if (!firstSeen) {
      localStorage.setItem(RZ_FIRST_SEEN, Date.now())
    }
    if (pagesVisited == PAGES_VISITED) {
      showPopUpBanner()
    }
    if (pagesVisited > PAGES_VISITED) {
      clearInterval(timer)
    }
  }

  function trackUserActivity() {
    console.log("tic tac")
    let firstSeen = parseInt(localStorage.getItem(RZ_FIRST_SEEN)) || Date.now()
    if (Date.now() - firstSeen > BEEN_ACTIVE) {
      showPopUpBanner()
    }
  }
  timer = setInterval(trackUserActivity, 15000)
  initUserActivityTracking()
})
