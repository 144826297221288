import Swiper, { Navigation, Pagination, Autoplay, Controller, Lazy } from 'swiper';

import '../styles/index.scss'
import '../styles/new-pages.scss'

import './common/twinspark'
import './common/popup-banner'
import './common/header'

import * as forms from './forms'

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementById("index-slider")) {
    new Swiper("#index-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: "#index-slider .swiper-pagination",
        clickable: true,
      },
    })
  }

  $('.product-favorites-add:not([ts-action])').click(function(e) {
    e.preventDefault();

    let product_id = $(e.currentTarget)[0].dataset['product'];
    let model_name = $(e.currentTarget)[0].dataset['modelname'];
    let price = $(e.currentTarget)[0].dataset['price'];

    let fav_use = $(this).find('use');
    let fav_text = $(this).find('.fav-btn-text');

    $(this).toggleClass("_active")

    var added = $(this).hasClass('_active')
    fav_text.text(added ? 'Добавлено в избранное' : 'В избранное')
    fav_use.attr('xlink:href', added ? '#star2-fill' : '#star2')

    send_add_favorites_ajax(product_id, model_name, added, price);
  });
})

document.addEventListener("ProductListLoaded", () => {
  if (document.querySelectorAll(".product-slider").length) {
    new Swiper(".product-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      spaceBetween: 15,
      slidesPerView: 1,
      pagination: {
        el: '.swiper__dots',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        476: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    })
  }

})

document.addEventListener("PortfolioListLoaded", () => {
  if (document.querySelectorAll(".portfolio-slider").length) {
    new Swiper(".portfolio-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      spaceBetween: 15,
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      watchOverflow: true,
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: '.swiper__dots',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3,
        },
      },
    })
  }
})
